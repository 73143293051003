<template>
  <!--begin::Stats Widget 10-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div
        class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
      >
        <span class="symbol  symbol-50 symbol-light-success mr-2">
          <span class="symbol-label">
            <span class="svg-icon svg-icon-xl svg-icon-success">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Shopping/Cart3.svg-->
              <i class="fas fa-file-invoice fa-2x text-success"></i>
              <!--end::Svg Icon-->
            </span>
          </span>
        </span>
        <div class="d-flex flex-column text-right">
          <span class="text-dark-75 font-weight-bolder font-size-h3" v-if="sumLoad == false">Rp 0</span>
          <span class="text-dark-75 font-weight-bolder font-size-h3" v-else-if="sumLoad == true">Rp {{parseInt(sum[0].final_amount).toLocaleString('id-ID')}}</span>
          <span class="text-muted font-weight-bold mt-2">Pendapatan Rumah Sakit 7 hari terakhir</span>
        </div>
      </div>
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="area"
        width="100%"
        v-if="chartLoad == true"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 10-->
</template>

<script>
import { mapGetters } from "vuex";
import module from '@/core/modules/CrudModule.js'
import moment from 'moment'
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Jumlah ",
          data: []
        }
      ],
      sum:"",
      arr:[],
      chartLoad:false,
      sumLoad:false
    };
  },
  methods:{
    async getSummary(){
      this.sum = await module.get('payments-dashboard-income')
       if(this.sum.length == 0){
        this.sumLoad = false
      }else{
        this.sumLoad = true
      }
    },

    async getData(){
      let response = await module.get('payments-dashboard-graphic')
      
      
      
      let arr = []
      let a
      let b = 0

      for (a = 0; a <= 6; a++) {
      let dateCategory = moment().subtract(6,'d').add('days',a).format('DD-MM-YYYY')

      if(response.length != 0){
        var value = response[b].final_amount
        var name = response[b].display_created_at
        if (dateCategory == name) {
          var arrSuccess = {
            "name": name,
            "value": value
          }
          arr.push(arrSuccess)
          if (b < response.length - 1) {
            b++
          }

        } else {
          var arrFail = {
            "name": dateCategory,
            "value": 0
          }
          arr.push(arrFail)
          
        }
        // if (b < response.length - 1) {
        //     b++
        // }
      }else{
        var arrFail = {
          "name": dateCategory,
          "value": 0
        }
        arr.push(arrFail)
         
      }
    }
        this.series[0].data = arr.map(arr => parseFloat(arr.value))
        window.arrData = arr.map(arr => String(arr.name))
        this.chartLoad = true
        

        this.chartOptions = {
      chart: {
        type: "area",
        height: 100,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 1
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.layoutConfig("colors.theme.base.success")]
      },
      xaxis: {
        categories: window.arrData,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        show: false,
        min: 0,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return "Rp" + parseInt(val).toLocaleString('id-ID') ;
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.light.success")],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.success")],
        strokeColor: [this.layoutConfig("colors.theme.base.success")],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
    }

  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.getSummary()
    this.getData()
    if(this.chartLoad == true){
      
    }
    // reference; kt_stats_widget_12_chart
    
  }
};
</script>
